import React, { Component } from 'react'
import { Page, View, SEO } from 'lib'
import ContactForm from './sections/_contactForm'
import { makeStyles } from '@material-ui/styles'

const Homepage = (props) => {

  const styles = useStyles(props)
  return (
    <Page fullWidth disableNavbar disableTopPadding>
      <SEO title={'Contact us'}/>
      <View className={styles.wrapper}>
        <ContactForm/>
      </View>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100vw',
    minHeight: `100vh`,
    display: 'flex',
    background: theme.palette.primary.main,
    ...theme.centralise,
  },
}))

export default Homepage
