import React, { Component, useState, Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import { View, Button, Fetch, Input, Text, LabeledCheckbox } from 'lib'

import Settings from '_appSetup/Settings'

const statusDict = {
  sending: 'Please wait...',
}

const interestDict = {
  new: 'New app or software project',
  consultation: 'Tech audit / consultation',
  notSure: 'Not sure yet',
}

const ContactForm = (props) => {
  const [stage, setStage] = useState(0)
  const [interests, setInterests] = useState([])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState(null)

  const toggleInterest = (item) => {
    const newInterests = interests.includes(item) ? interests.filter(i => i !== item) : [...interests, item]
    setInterests(newInterests)
  }

  const validateEmail = () => {
    const regex = /[\w-]+@([\w-]+\.)+[\w-]+/
    return regex.test(email)
  }

  const nameInvalid = name.length < 3 && !props.basic
  const emailInvalid = !validateEmail()

  const calendlyLink = `${Settings.POST_CONTACT_REDIRECT_URL}?name=${name}&email=${email}`

  const submit = () => {
    setStatus('sending')
    const data = {
      name,
      email,
      phone,
      website,
      description,
      interests: interests.map(i => interestDict[i]).join(', '),
    }
    Fetch({
      url: '/contactFormEnquiry',
      method: 'POST',
      data: data,
      options: {
        json: true,
      },
      successCallback: () => {
        window.location.href = calendlyLink
        setTimeout(() => {
          setStage(3)
        }, 2000)
      },
      failureCallback: () => {
        setStatus(null)
      },
    })

    if (window.gtag) window.gtag('event', 'Contact form - submit enquiry', data)
  }

  const buttonText = status in statusDict ? statusDict[status] : `Next →`
  const styles = props.classes

  const steps = [
    `What services are you looking for?`,
    `Tell us a bit about you`,
    `How can we get in touch with you?`,
  ]

  const updateStage = (newStage) => {
    setStage(newStage)
    if (window.gtag) window.gtag('event', 'Contact form - change stage', { stage: newStage })
  }

  return (
    <View style={styles.wrapper}>

      {stage < 3 && <Text variant='h5' style={styles.cta}>{steps[stage]}</Text>}

      {stage == 0 ? (

        <>

          {Object.keys(interestDict).map((int, i) => (
            <LabeledCheckbox
              key={i}
              value={interests.includes(int)}
              label={interestDict[int]}
              style={styles.checkboxStyle}
              onChange={() => toggleInterest(int)}
            />
          ))}

        </>

      ) : stage == 1 ? (

        <>
          <Text style={`${styles.inputStyle} ${styles.inputLabel}`}>
      What project do you need help with? What does your business do? This is optional, but helps get the ball rolling.
          </Text>

          <Input
            placeholder='Type here...'
            required
            multiline
            autoFocus
            rows={6}
            style={styles.inputStyle}
            onChange={e => setDescription(e)}
            value={description}/>

        </>

      ) : stage == 2 ? (

        <>

          <Text style={`${styles.inputStyle} ${styles.inputLabel}`}>
          We aim to get back to you asap, usually the same working day.
          </Text>

          <Input
            required
            autoFocus
            label='Name'
            style={styles.inputStyle}
            error={nameInvalid && name.length > 0}
            onChange={e => setName(e)}
            value={name}/>

          <Input
            type='email'
            label={`Email`}
            style={styles.inputStyle}
            error={emailInvalid && email.length > 0}
            onChange={e => setEmail(e)}
            value={email}/>

          <Input
            label={`Phone number (optional)`}
            style={styles.inputStyle}
            onChange={e => setPhone(e)}
            value={phone}/>

          <Input
            label={`Company website (optional)`}
            style={styles.inputStyle}
            onChange={e => setWebsite(e)}
            value={website}/>

        </>

      ) : stage == 3 ? (
        <>

          <View style={styles.statusTextWrapper}>
            <Text variant='h5' style={styles.confirmationHeader}>Nice to meet you!</Text>
            <Text text={`Next, let's discuss your project in more detail.`} style={styles.descriptionSentLabel}/>
          </View>

        </>
      ) : null}

      <View style={styles.sendButtonWrapper}>
        {stage > 0 && stage < 3 && <Button className={styles.backButton} onClick={() => updateStage(stage - 1)}>← Back</Button>}
        {stage < 2 ?
          <Button
            inverted
            disabled={!interests.length}
            className={styles.sendButton}
            color='primary'
            onClick={() => updateStage(stage + 1)}>
              Next →
          </Button>
          : stage == 2 ?
            <Button
              inverted
              disabled={nameInvalid || emailInvalid}
              className={styles.sendButton}
              onClick={submit}>
              {buttonText}
            </Button>
            : stage == 3 ?
              <View style={styles.endButtons}>
                <Button
                  inverted
                  className={styles.sendButton}
                  href={calendlyLink}>Book a call
                </Button>
              </View>
              : null
        }
      </View>

    </View>
  )
}

const sideMargin = 32

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 500,
    width: '100%',
  },
  inputStyle: {
    marginLeft: sideMargin,
    marginRight: sideMargin,
    marginTop: theme.spacing(1),
    color: 'white',
  },
  inputLabel: {
    color: 'white',
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  checkboxStyle: {
    marginLeft: sideMargin - theme.spacing(1.25),
    marginRight: sideMargin - theme.spacing(1.25),
  },
  cta: {
    color: 'white',
    marginLeft: sideMargin,
    marginRight: sideMargin,
    marginBottom: theme.spacing(2),
  },
  sendButtonWrapper: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sendButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.typography.color,
    [`&:hover`]: {
      backgroundColor: theme.palette.primary.light,
    },
    alignSelf: 'center',
  },
  descriptionSentLabel: {
    color: 'white',
    width: 300,
  },
  confirmationHeader: {
    color: 'white',
    marginBottom: theme.spacing(2),
  },
  statusTextWrapper: {
    marginLeft: sideMargin,
    marginRight: sideMargin,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  backButton: {
    backgroundColor: 'transparent',
    [`&:hover`]: {
      backgroundColor: 'transparent',
    },
  },
  endButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export default withStyles(styles)(ContactForm)
